import FontAwesome from './FontAwesome'
import Modal from './Modal.vue'
import ModalApp from './ModalApp.vue'
import ErrorMsg from './ErrorMessage.vue'

export default {
  install (Vue) {
    Vue.component('ModalBase', Modal)
    Vue.component('ModalApp', ModalApp)
    Vue.component('GErrorMsg', ErrorMsg)
    Vue.use(FontAwesome)
  },
}