export default {
  path: '/',
  component: () => import(/* webpackChunkName: "auth" */ '../layouts/Auth/Index.vue'),
  children: [
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "auth" */ '../views/Auth/Login.vue'),
    },
    {
      path: '/password/forgot',
      name: 'forgot',
      component: () => import(/* webpackChunkName: "auth-forgot" */ '../views/Auth/PasswordRequest.vue'),
    },
    {
      path: '/reset-password/:token',
      name: 'reset',
      component: () => import(/* webpackChunkName: "auth-reset" */ '../views/Auth/PasswordReset.vue'),
    },
  ],
}