// src/client/build/register.ts
var autoUpdateMode = "true";
var selfDestroying = "false";
var auto = autoUpdateMode === "true";
var autoDestroy = selfDestroying === "true";
function registerSW(options = {}) {
  const {
    immediate = false,
    onNeedRefresh,
    onOfflineReady,
    onRegistered,
    onRegisteredSW,
    onRegisterError
  } = options;
  let wb;
  let registration;
  let registerPromise;
  let sendSkipWaitingMessage;
  const updateServiceWorker = async (_reloadPage = true) => {
    await registerPromise;
    if (!auto) {
      await (sendSkipWaitingMessage == null ? void 0 : sendSkipWaitingMessage());
    }
  };
  async function register() {
    if ("serviceWorker" in navigator) {
      const { Workbox } = await import("workbox-window");
      wb = new Workbox("/sw.js", { scope: "/", type: "classic" });
      sendSkipWaitingMessage = async () => {
        if (registration && registration.waiting) {
          await (wb == null ? void 0 : wb.messageSkipWaiting());
        }
      };
      wb.addEventListener("activated", (event) => {
        if (!event.isUpdate && event.isExternal)
          window.location.reload();
        else if (event.isUpdate)
          auto && window.location.reload();
        else if (!autoDestroy)
          onOfflineReady == null ? void 0 : onOfflineReady();
      });
      if (!auto) {
        const showSkipWaitingPrompt = () => {
          wb == null ? void 0 : wb.addEventListener("controlling", (event) => {
            if (event.isUpdate)
              window.location.reload();
          });
          onNeedRefresh == null ? void 0 : onNeedRefresh();
        };
        wb.addEventListener("waiting", showSkipWaitingPrompt);
        wb.addEventListener("externalwaiting", showSkipWaitingPrompt);
      }
      wb.register({ immediate }).then((r) => {
        registration = r;
        if (onRegisteredSW)
          onRegisteredSW("/sw.js", r);
        else
          onRegistered == null ? void 0 : onRegistered(r);
      }).catch((e) => {
        onRegisterError == null ? void 0 : onRegisterError(e);
      });
    }
  }
  registerPromise = register();
  return updateServiceWorker;
}
export {
  registerSW
};
