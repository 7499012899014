import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

export default function (app, router) {
  if (import.meta.env.DEV) {
    return
  }

  Sentry.init({
    app,
    dsn: 'https://6c411c7bb71c42879a4710a1cc680f26@o245670.ingest.sentry.io/6107568',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['spg.rain-api.test', import.meta.env.VITE_SERVER_HOST, /^\//],
      }),
    ],
    ignoreErrors: [
      // axios server common https statuses
      'Request failed with status code 401',
      'Request failed with status code 403',
      'Request failed with status code 404',
      'Request failed with status code 419',
      'Request failed with status code 422',
      'Request failed with status code 429',
      'Request failed with status code 500',
      'Request failed with status code 502',
      'Request failed with status code 503',
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  })
  Sentry.setTag('app_version', import.meta.env.PACKAGE_VERSION)
}