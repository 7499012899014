import { createRouter, createWebHistory } from 'vue-router'

import authRoutes from './auth'

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "dashboard" */ '../layouts/Dashboard/Index.vue'),
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
      },
      {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: () => import(/* webpackChunkName: "errors" */ '../views/Error/404.vue'),
      },
      {
        path: '/search',
        name: 'Search',
        component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
      },
      {
        path: '/account',
        name: 'Account',
        component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
      },
      {
        path: '/point-today',
        name: 'PointToday',
        component: () => import(/* webpackChunkName: "point-today" */ '../views/PointToday.vue'),
      },
      {
        path: '/notification',
        name: 'Notification',
        component: () => import(/* webpackChunkName: "notification" */ '../views/Notification.vue'),
      },
      {
        path: '/most-wanted',
        name: 'MostWanted',
        component: () => import(/* webpackChunkName: "most-wanted" */ '../views/MostWanted.vue'),
      },
      {
        path: '/history',
        name: 'History',
        component: () => import(/* webpackChunkName: "history" */ '../views/History.vue'),
      },
      {
        path: '/update-password',
        name: 'UpdatePassword',
        component: () => import(/* webpackChunkName: "updatePassword" */ '../views/UpdatePassword.vue'),
      },
      {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "updatePassword" */ '../views/About.vue'),
      },
      {
        path: '/help',
        name: 'Help',
        component: () => import(/* webpackChunkName: "updatePassword" */ '../views/Help/Index.vue'),
      },
    ],
  },
  authRoutes,
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
