<template>
  <div v-if="text" class="py-1 text-xs text-red-500">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'ErrorMessage',
  props: {
    message: {
      type: [Array, String],
      default: () => [],
    },
  },
  computed: {
    text () {
      if (Array.isArray(this.message) && this.message.length) {
        return this.message[0]
      }

      return null
    },
  },
}
</script>