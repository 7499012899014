import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueQrcode from '@chenfengyuan/vue-qrcode'

// import './firebase'
// import sentry from './sentry'

// global stuffs
import globalComponents from './components'
// import globalDirectives from './directives'

// styles
import './assets/tailwind.css'

// helper functions
import Api from './functions/api.js'
import formatter from './functions/formatters'
import { registerSW } from 'virtual:pwa-register'
registerSW()

const app = createApp(App)
app.config.globalProperties.fmt = formatter
app.config.globalProperties.api = new Api(router)
app.config.performance = true

app.component(VueQrcode.name, VueQrcode)

app.use(store)
  .use(router)
  .use(globalComponents)
  // .use(globalDirectives)

app.mount('#app')

import Sentry from './sentry'
Sentry(app, router)
// sentry.init(app)