<template>
  <router-view/>
  <AppToast/>
</template>

<script>
import AppToast from './components/AppToast.vue'
import { toasts, toast } from './functions/toast'
import { provide, readonly } from 'vue'
export default {
  name: 'App',
  components: {
    AppToast,
  },
  setup () {
    provide('toasts', readonly(toasts))
    provide('toast', toast)
  },
  mounted () {
    this.initCsrfToken()
  },
  methods: {
    async initCsrfToken () {
      await this.api.GET('/sanctum/csrf-cookie')
    },
  },
}
</script>