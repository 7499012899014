export default {
  number: num => {
    return num.toLocaleString('id', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
  },

  price: num => {
    return num.toLocaleString('id', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  },

  decimal: num => {
    return num.toLocaleString('id', { minimumFractionDigits: 0, maximumFractionDigits: 2 })
  },
  numberAbbreviate: num => {
    return num
  },
}