// https://www.npmjs.com/package/@fortawesome/vue-fontawesome

import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faArrowRightFromBracket,
  faBarcodeRead,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle as farCircle,
  faCircleInfo,
  faCircleQuestion,
  faCircleXmark,
  faCopy,
  faEnvelope as farEnvelope,
  faEye,
  faEyeSlash,
  faFaceSmileWink,
  faLock,
  faMagnifyingGlass,
  faQrcode,
  faRotate,
  faSquare,
  faUser,
  faUserPen,
} from '@fortawesome/pro-regular-svg-icons'

library.add(
  faArrowRightFromBracket,
  faBarcodeRead,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  farCircle,
  faCircleInfo,
  faCircleQuestion,
  faCircleXmark,
  faCopy,
  farEnvelope,
  faEye,
  faEyeSlash,
  faFaceSmileWink,
  faLock,
  faMagnifyingGlass,
  faQrcode,
  faRotate,
  faSquare,
  faUser,
  faUserPen,
)

import {
  faBell,
  faCartFlatbedEmpty,
  faCircle,
  faDotCircle,
  faEnvelope,
  faEnvelopeOpen,
  faExclamation,
  faHouse,
  faShop,
  faSlash,
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  faBell,
  faCartFlatbedEmpty,
  faCircle,
  faDotCircle,
  faEnvelope,
  faEnvelopeOpen,
  faExclamation,
  faHouse,
  faShop,
  faSlash,
)

export default {
  install (Vue) {
    Vue.component('FaIcon', FontAwesomeIcon)
    Vue.component('FaLayers', FontAwesomeLayers)
    Vue.component('FaLayersText', FontAwesomeLayersText)
  },
}