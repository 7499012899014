import { createStore } from 'vuex'
export default createStore({
  state: {
    userId: null,
    name: '',
    point: 0,
    code: '',
    menu: 'home',
    label: '',
    showMenu: true,
    showHeader: false,
  },
  mutations: {
    changeMenu (state, menu) {
      state.showHeader = menu.header
      state.showMenu = menu.footer
      state.menu = menu.menu
      state.label = menu.label
    },
    setUserId (state, userId) {
      state.userId = userId
    },
    setName (state, name) {
      state.name = name
    },
    setPoint (state, point) {
      state.point = point
    },
    setCode (state, code) {
      state.code = code
    },
  },
  getters: {
  },
  actions: {
  },
  modules: {
  },
})