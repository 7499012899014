<template>
  <teleport to="body">
    <div v-if="dim" class="fixed inset-0 z-50 flex items-center justify-center">
      <div class="fixed inset-0 bg-black bg-opacity-40" @click="close"></div>

      <transition
        name="modal"
        @after-leave="afterLeave"
        @after-enter="$emit('opened')">
        <div v-if="display" class="fixed w-full max-w-lg px-4">
          <slot name="header"></slot>
          <slot name="content"></slot>
        </div>
      </transition>
    </div>
  </teleport>
</template>

<script>
export default {
  name: 'ModalBase',

  emits: ['opened', 'closed'],

  data () {
    return {
      display: false,
      dim: false,
    }
  },

  methods: {
    open () {
      this.dim = true
      this.$nextTick(() => {
        this.display = true
      })
    },
    close () {
      this.display = false
      document.removeEventListener('keydown', this.onEscapeListener)
      document.removeEventListener('backbutton', this.onEscapeListener)

    },
    afterLeave () {
      this.dim = false
      this.$emit('closed')
    },

    onEscapeListener (event) {
      if (event.key === 'Escape') {
        this.close()
      }
    },
  },
}
</script>

<style lang="postcss" scoped>
  .modal-enter-active,
  .modal-leave-active {
      transition: opacity 0.3s ease, margin-top 0.3s ease;
  }
  .modal-enter-from,
  .modal-leave-to {
      margin-top: -50px;
      opacity: 0;
  }
</style>
